<template>
  <div :class="$style[classes]">
		<ul>
			<li @click="toPhoneSetting"><span>手机号码</span><i class="iconfont icon-xuanze"></i></li>
			<li @click="toPasswordSetting"><span>密码</span><i class="iconfont icon-xuanze"></i></li>
			<li @click="toLogout"><span>退出登录</span><i class="iconfont icon-xuanze"></i></li>
		</ul>
	</div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-user-setting'

export default {
  name: 'pt-user-setting',
  computed: {
    classes () {
      return `${prefixCls}`
    }
  },
  methods:{
    toPhoneSetting (){
      this.$router.push('/phone-setting')
    },
    toPasswordSetting (){
      this.$router.push('/password-setting')
		},
		toLogout(){
			this.$router.push('login')
		}
  }
}
</script>

<style module lang="less">
@import "~less/var.less";
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.pt-user-setting{
	ul{
		background-color: #fff;
		li{
			line-height:50px;
			display:flex;
			padding: 0 @page-padding;
			justify-content: space-between;
			&+li{
				border-top: 1Px solid @font-of-grey
			}
		}
		.global{
			.icon-xuanze{
				font-size: 12px;
			}
		}
	}
}
</style>
