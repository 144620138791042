<template>
  <pt-user-setting class="pt-user-setting__page"></pt-user-setting>
</template>

<script>
import PtUserSetting from 'cps//pt-user-setting/withData'
export default {
  name: 'pt-user-setting__view',
  components: {
    'pt-user-setting': PtUserSetting
  }
}
</script>
