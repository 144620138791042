<template>
  <pt-user-setting class="pt-user-setting__withData"></pt-user-setting>
</template>

<script>
import PtUserSetting from './index'
export default {
  name: 'pt-user-setting__withData',
  components: {
    'pt-user-setting': PtUserSetting
  }
}
</script>
